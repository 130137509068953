<template>
  <v-card elevation="10" class="ma-auto" max-width="600" shaped>
    <v-card-text class="title text-center">Missing permission </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MissingPermissionsPage",
  components: {},
};
</script>